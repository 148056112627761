// react hooks
import React, { useEffect, useState } from 'react';
//@ts-ignore
import { Link } from 'react-router-dom';

// routes, interfaces
import IPage from '../../interfaces/page';
import * as ROUTES from '../../constants/routes';
import { motion } from 'framer-motion';
import { pageAnimation } from '../../components/animations';

// images, axios
//@ts-ignore
import icon from '../../assets/images/logOut-icon.png';
import axios from 'axios';

const AdminLogOut: React.FunctionComponent<IPage> = (props: any) => {
	useEffect(() => {
		props.logged(false)
		localStorage.removeItem("user")
	},[])

	// 	return () => {
	// 		unmounted = true;
	// 	};
	// }, []);

	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
			className={
				'mainContainer md:pt-64 pt-36 xl:pb-44 lg:pb-12 pb-12 xl:px-24 lg:px-16 md:px-12 px-4 flex justify-center'
			}
		>
			{/* <UserSidebar activeSideBar={activeSideBar} /> */}
			<div className="logOut__cotainer md:mx-8 flex justify-center items-center flex-col mx-auto md:px-12 px-6 py-12">
				<img src={icon} alt="ikona" className="w-20" />
				<h3 className="my-7 text-center w-full">Byli jste úspěšně odhlášeni</h3>
				<Link to={ROUTES.HOME}>
					<button className="rounded-xl text-white px-6 py-3">
						Zpět na hlavní stránku
					</button>
				</Link>
			</div>
		</motion.div>
	);
};

export default AdminLogOut;
