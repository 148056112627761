// react hooks
import React, { useEffect, useRef, useState } from 'react';
//@ts-ignore
import { Link, useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Editor } from '@tinymce/tinymce-react';

// routes, interfaces
import IPage from '../../interfaces/page';
import { ADDRESS } from '../../constants/routes';

import { motion } from 'framer-motion';
import { pageAnimation } from '../../components/animations';

// fetch, axios
// import { addRow, deleteRow, FetchRows } from '../../components/fetch';
import axios from 'axios';

// components
import Sidebar from '../../components/adminSidebar';
import SidebarButton from '../../components/sideBarButton';

// icons
import icon from '../../assets/images/admin-blog-icon.png';
import NumberFormat from "react-number-format";

const AdminProductsAdd: React.FunctionComponent<IPage> = props => {
	let history = useHistory()
	const [btnText, setBtnText] = useState('Přidat kategorii');
	const [errorMsg, setErrorMsg] = useState('');
	const [succMsg, setSuccMsg] = useState('');
	const editorRef = useRef<any>();
	const editorRef2 = useRef<any>();
	const [gallery, setGallery] = useState([]);
	const [welcomePhoto, setWelcomePhoto] = useState('');
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [label, setLabel] = useState('');
	const [cena, setCena] = useState('');
	const [available, setAvailable] = useState('');
	const [categoryChoice, setCategoryChoice] = useState('');
	const [labelErr, setLabelErr] = useState('');
	const [cenaErr, setCenaErr] = useState('');
	const [availableErr, setAvailableErr] = useState('');
	const [categoryErr, setCategoryErr] = useState('');
	const [fileErr, setFileErr] = useState('');
	const [textErr, setTextErr] = useState('');
	const [textErr2, setTextErr2] = useState('');
	const [productErr, setProductErr] = useState('');
	const [productSuccErr, setProductSuccErr] = useState('');
	const changeHandler = (event: {
		target: { files: React.SetStateAction<undefined>[] };
	}) => {
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
		if (event.target.files[0]) {
			setWelcomePhoto(event.target.files[0].name);
		}
	};

	type Inputs = {
		category_name: string;
	};
	const {
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<Inputs>();

	// const xd = async () => {
	// 	const res = await axios.get(`${ADDRESS}/product-all-image`, {
	// 		withCredentials: true,
	// 	});
	// 	setGallery(res.data);
	// };
	//
	// useEffect(() => {
	// 	xd();
	// }, []);

	const log = async (e: any) => {
		e.preventDefault();
		setLabelErr('');
		setCategoryErr('');
		setCenaErr('')
		setAvailableErr('')
		setFileErr('');
		setTextErr('');
		setTextErr2('');
		if (editorRef.current) {
			const formData = new FormData();
			const productObj = {
				label: label,
				category: categoryChoice,
				cena: cena,
				available: available,
				// @ts-ignore
				shortDescription: editorRef.current.getContent(),
				longDescription: editorRef2.current.getContent(),
				img: selectedFile,
			};
			let errors = 0
			if (label === '') {
				setLabelErr('Název je povinný!');
				errors++
			}

			if (cena === '') {
				setCenaErr('Cena je povinná!');
				errors++

			}

			if (available === '') {
				setAvailableErr('Počet kusů je povinný!');
				errors++

			}
			if (categoryChoice === '') {
				setCategoryErr('Kategorie je povinná!');
				errors++

			}

			if (productObj.shortDescription === '') {
				setTextErr('Text je povinný!');
				errors++

			}
			if (productObj.longDescription === '') {
				setTextErr2('Text je povinný!');
				errors++

			}

			if (welcomePhoto === '') {
				setFileErr('Obrázek je povinný!');
				errors++

			}

			for (let product_data in productObj) {
				// @ts-ignore
				if (product_data === 'image') {
					// @ts-ignore
					formData.append('image', productObj['image']);
				} else {
					// @ts-ignore
					formData.append(product_data, productObj[product_data]);
				}
			}

			// @ts-ignore
			// for (var pair of formData.entries()) {
			// 	console.log(pair[0] + ', ' + pair[1]);
			// }

			// if (
			// 	Object.values(productObj).filter(
			// 		item => item === '' || item === 'Vybrat kategorii',
			// 	).length === 0
			// ) {
			if (!errors){
				await axios(`${ADDRESS}/save/products`, {
					withCredentials: false,
					method: 'POST',
					data: formData,
				})
					.then(res=>{
						if (res.data.success){
							if(props.update){
								props.update()
							}
							history.push("/admin/produkty")
						}
					})
					.catch(err => {
					if (err) {
						setProductErr('Produkt se nepodařilo přidat');
					}
				});
			}
		}
	};

	const [activeSideBar, setActiveSideBar] = useState(Boolean);
	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
			className={'adminContainer'}
		>
			<div className={'addedControlls'}>
				<SidebarButton
					setActiveSideBar={setActiveSideBar}
					activeSideBar={activeSideBar}
				/>
				<div>
					<Link
						to={'/admin/produkty'}
						id={'adminHelperBtn'}
						className={'adminHelperBtn'}
					>
						Zpět na výpis
					</Link>
				</div>
			</div>
			<Sidebar activeSideBar={activeSideBar} />
			<div className={'addedRowForm blogAdd py-4 px-3'}>
				<div className={'rowForm editor'}>
					<form>
						<div className={'rowForm'}>
							<input
								className={'basicInput adminInput'}
								type={'text'}
								placeholder={'Název produktu'}
								value={label}
								onChange={evt => setLabel(evt.target.value)}
							/>
							<div className="my-2">
								{labelErr ? (
									<p className={'error leading-7 p-0 m-0'}>{labelErr}</p>
								) : null}
							</div>
							<select
								className={'basicInput adminInput'}
								onChange={evt => setCategoryChoice(evt.target.value)}
							>
								<option disabled hidden selected>Kategorie produktu</option>
								<option value="Online">Online</option>
								<option value="Osobně">Osobně</option>
							</select>
							<div className="my-2">
								{categoryErr ? (
									<p className={'error leading-7 p-0 m-0'}>{categoryErr}</p>
								) : null}
							</div>
							<NumberFormat
								className={'basicInput adminInput'}
								decimalScale={0}
								placeholder={"Cena produktu"}
								decimalSeparator={" "}
								allowNegative={false}
								thousandSeparator={"."}
								type={"text"}
								suffix={" Kč"}
								value={cena}
								onValueChange={(val)=>setCena(val.formattedValue)}
							/>
							<div className="my-2">
								{cenaErr ? (
									<p className={'error leading-7 p-0 m-0'}>{cenaErr}</p>
								) : null}
							</div>
							<input
								className={'basicInput adminInput'}
								type={'text'}
								placeholder={'Počet kusů produktu'}
								value={available}
								onChange={evt => setAvailable(evt.target.value)}
							/>
							<div className="my-2">
								{availableErr ? (
									<p className={'error leading-7 p-0 m-0'}>{availableErr}</p>
								) : null}
							</div>
							<label>
								<input
									type="file"
									className="hidden"
									// value={welcomePhoto}
									name="image"
									// @ts-ignore
									onChange={changeHandler}
									accept="image/png, image/gif, image/jpeg"
								/>
								<div className="flex input-container h-full cursor-pointer basicInput adminInput fileInput items-center gap-x-4 ">
									<img src={icon} alt="icon" className="w-10" />
									{welcomePhoto === '' ? (
										<p>Prosím vložte obrázek</p>
									) : (
										<p>{welcomePhoto}</p>
									)}
								</div>
								<div className="my-2">
									{fileErr ? (
										<p className={'error leading-7 p-0 m-0'}>{fileErr}</p>
									) : null}
								</div>
							</label>
						</div>
						<p className="description">Krátký popis</p>
						<div className={'rowForm'}>
							{
								<Editor
									apiKey="a8zubyyl16d8v8m0k5jnlgm34byg8qg0hoelznpecrq9io2l"
									// @ts-ignore
									onInit={(evt, editor) => (editorRef.current = editor)}
									init={{
										height: 500,
										menubar: 'insert',
										images_upload_url: `${ADDRESS}/product-new-image`,
										automatic_uploads: true,
										images_upload_credentials: true,
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'image',
											'insertdatetime media table paste code help wordcount',
										],
										toolbar:
											'undo redo | formatselect | ' +
											'bold italic backcolor | alignleft aligncenter ' +
											'alignright alignjustify | bullist numlist outdent indent | ' +
											'image' +
											'removeformat | help',
										image_list: gallery,
										content_style:
											'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
									}}
								/>
							}
							<div className="my-2">
								{textErr ? (
									<label className={'error leading-7 p-0 m-0'}>{textErr}</label>
								) : null}
							</div>
						</div>
						<p className="description">Dlouhý popis</p>
						<div className={'rowForm'}>
							{
								<Editor
									apiKey="a8zubyyl16d8v8m0k5jnlgm34byg8qg0hoelznpecrq9io2l"
									// @ts-ignore
									onInit={(evt, editor) => (editorRef2.current = editor)}
									init={{
										height: 800,
										menubar: 'insert',
										images_upload_url: `${ADDRESS}/product-new-image`,
										automatic_uploads: true,
										images_upload_credentials: true,
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'image',
											'insertdatetime media table paste code help wordcount',
										],
										toolbar:
											'undo redo | formatselect | ' +
											'bold italic backcolor | alignleft aligncenter ' +
											'alignright alignjustify | bullist numlist outdent indent | ' +
											'image' +
											'removeformat | help',
										image_list: gallery,
										content_style:
											'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
									}}
								/>
							}
							<div className="my-2">
								{textErr2 ? (
									<label className={'error leading-7 p-0 m-0'}>
										{textErr2}
									</label>
								) : null}
							</div>
						</div>
						{productErr !== '' ? (
							<p className="errorAlert">{productErr}</p>
						) : null}
						{productSuccErr !== '' ? (
							<p className="succAlert">{productSuccErr}</p>
						) : null}
						<div className="w-full text-center mt-5 mb-1">
							<button
								onClick={e => {
									log(e);
								}}
								type="submit"
								className="btn-default mx-auto rounded-xl text-white btn-add px-6 py-1.5 font-semibold"
							>
								Přidat
							</button>
						</div>
					</form>
				</div>
			</div>
		</motion.div>
	);
};

export default AdminProductsAdd;
