import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

const ScrollToTop = props => {
	const location = useLocation();
	let history = useHistory();
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	}, [location]);

	return <>{props.children}</>;
};

export default ScrollToTop;