import * as ROUTES from '../constants/routes';
import { Link } from 'react-router-dom';

export default function Footer({logged}) {
	return (
		<div className="footer-container bg-gray-nadpis flex justify-center items-center  py-2">
			<div className={"grid xs:grid-cols-3 grid-cols-1 py-4 gap-y-2 text-white font-medium md:grid-cols-"
			+ (!logged ? "7" : "5")}>
				<div className="col-span-3 text-center w-full">
					Copyright 2022 © Tomáš Kašpar
				</div>

				{/*<div className="col-span-3 text-center transitions hover:text-main-red cursor-pointer w-full">*/}
				{/*	<Link to={`${ROUTES.GDPR}`}>Zpracování osobních údajů</Link>*/}
				{/*</div>*/}
				{/*<div className="md:col-span-1  col-span-3 transitions text-center hover:text-main-red cursor-pointer w-full">*/}
				{/*	<Link to={`${ROUTES.COOKIES}`}>Cookies</Link>*/}
				{/*</div>*/}

				<div className="md:col-span-2 col-span-3 text-center w-full">
					Vytvořil{' '}
					<span className="hover:text-main-red cursor-pointer transitions">
						<a href="https://yeetzone.com/" target="_blank" rel="noreferrer">
							Yeetzone
						</a>
					</span>
				</div>
				{!logged &&
				<div className="md:col-span-1 col-span-full transitions text-center hover:text-main-red cursor-pointer w-full">
					<Link to={`${ROUTES.PRIHLASENI}`}>Přihlášení</Link>
				</div>
				}
				 <div className={"flex-row justify-center items-center col-span-full flex"} style={{display:"none"}}><span className={"mr-3"}>Jazyk: </span><span className={" flex justify-center items-center"} id="google_translate_element"></span></div>

			</div>
		</div>
	);
}
