// react hooks
import React, { useEffect, useRef, useState } from 'react';
//@ts-ignore
import { Link, useHistory, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

// routes, interfaces
import IPage from '../../interfaces/page';
import { ADDRESS } from '../../constants/routes';

import { motion } from 'framer-motion';
import { pageAnimation } from '../../components/animations';

// fetch, axios
// import { addRow, deleteRow, FetchRows } from '../../components/fetch';
import axios from 'axios';

// components
import Sidebar from '../../components/adminSidebar';
import SidebarButton from '../../components/sideBarButton';

// icons
import icon from '../../assets/images/admin-blog-icon.png';
import sluzbyImg from '../../assets/images/sluzbyImg.png';
import sluzbyImg2 from '../../assets/images/sluzbyImg2.png';

//@ts-ignore

//Format number
import NumberFormat from "react-number-format"

const AdminProductsEdit: React.FunctionComponent<IPage> = props => {
	let history = useHistory()
	const [btnText, setBtnText] = useState('Přidat kategorii');
	const [errorMsg, setErrorMsg] = useState('');
	const [succMsg, setSuccMsg] = useState('');
	const editorRef = useRef<any>();
	const editorRef2 = useRef<any>();
	const [gallery, setGallery] = useState([]);
	const [img, setImg] = useState('');
	const [selectedFile, setSelectedFile] = useState();
	const [isFilePicked, setIsFilePicked] = useState(false);
	const [label, setLabel] = useState('');
	const [cena, setCena] = useState('');
	const [available, setAvailable] = useState('');
	const [fileErr, setFileErr] = useState('');
	const [textErr, setTextErr] = useState('');
	const [textErr2, setTextErr2] = useState('');
	const [labelErr, setLabelErr] = useState('');
	const [cenaErr, setCenaErr] = useState('');
	const [availableErr, setAvailableErr] = useState('');
	const [categoryErr, setCategoryErr] = useState('');
	const [categoryChoice, setCategoryChoice] = useState("")
    const [shortDescription, setShortDescription] = useState('');
    const [longDescription, setLongDescription] = useState('');
	const [startPhoto, steStartPhoto] = useState("")
	const [productErr, setProductErr] = useState("")
    const changeHandler = (event: {
        target: { files: React.SetStateAction<undefined>[] };
    }) => {
        setIsFilePicked(true);
        if (event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
            setImg(event.target.files[0].name);
        }
    };

	const { id } = useParams();

	type Inputs = {
		label: string;
		cena: string;
		available: string;
		welcome_photo: string;
		category: string;
		shortDescription: string;
		longDescription: string;
        img: any;
	};


	const log = async (e:any) => {
		e.preventDefault();
		setLabelErr('');
		setCategoryErr('');
		setCenaErr('')
		setAvailableErr('')
		setFileErr('');
		setTextErr('');
		setTextErr2('');
		if (editorRef.current) {
			const formData = new FormData();
			const productObj = {
				label: label,
				category: categoryChoice,
				cena: cena,
				available: available,
				// @ts-ignore
				shortDescription: editorRef.current.getContent(),
				longDescription: editorRef2.current.getContent(),
				img: selectedFile,
			};
			let errors = 0
			if (label === '') {
				setLabelErr('Název je povinný!');
				errors++
			}

			if (cena === '') {
				setCenaErr('Cena je povinná!');
				errors++

			}

			if (available === '') {
				setAvailableErr('Počet kusů je povinný!');
				errors++

			}
			if (categoryChoice === '') {
				setCategoryErr('Kategorie je povinná!');
				errors++

			}

			if (productObj.shortDescription === '') {
				setTextErr('Text je povinný!');
				errors++

			}
			if (productObj.longDescription === '') {
				setTextErr2('Text je povinný!');
				errors++

			}


			for (let product_data in productObj) {
				// @ts-ignore
				if (product_data === 'image') {
					// @ts-ignore
					formData.append('image', productObj['image']);
				} else {
					// @ts-ignore
					formData.append(product_data, productObj[product_data]);
				}
			}

			// @ts-ignore
			// for (var pair of formData.entries()) {
			// 	console.log(pair[0] + ', ' + pair[1]);
			// }

			// if (
			// 	Object.values(productObj).filter(
			// 		item => item === '' || item === 'Vybrat kategorii',
			// 	).length === 0
			// ) {
			if (!errors){
				await axios(`${ADDRESS}/update-row/products/${id}`, {
					withCredentials: false,
					method: 'POST',
					data: formData,
				})
					.then(res=>{
						if (res.data.success){
							if(props.update){
								props.update()
							}
							history.push("/admin/produkty")
						}
					})
					.catch(err => {
						if (err) {
							setProductErr('Produkt se nepodařilo upravit');
						}
					});
			}
		}
	};

	const [activeSideBar, setActiveSideBar] = useState(Boolean);


	useEffect(() => {
		axios.get(`${ADDRESS}/fetch-row/products/${id}`)
			.then(res=>{
				setLabel(res.data.name)
				setCategoryChoice(res.data.category)
				setCena(res.data.cost)
				setAvailable(res.data.quantity)
				steStartPhoto(res.data.photo.slice(16))
				setShortDescription(res.data.short_description)
				setLongDescription(res.data.long_description)
			})
	}, []);

	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
			className={'adminContainer'}
		>
			<div className={'addedControlls'}>
				<SidebarButton
					setActiveSideBar={setActiveSideBar}
					activeSideBar={activeSideBar}
				/>
				<div>
					<Link
						to={'/admin/produkty'}
						id={'adminHelperBtn'}
						className={'adminHelperBtn'}
					>
						Zpět na výpis
					</Link>
				</div>
			</div>
			<Sidebar activeSideBar={activeSideBar} />
			<div className={'addedRowForm blogAdd py-4 px-3'}>
				<div className={'rowForm editor'}>
					<form >
						<div className={'rowForm'}>
							<p className="description">Název:</p>
							<input
								className={'basicInput adminInput'}
								placeholder={'Název produktu'}
								value={label}
								onChange={evt => setLabel(evt.target.value)}
							/>
							<div className="my-2">
								{labelErr && (
									<p className="error leading-7 p-0 m-0">
										Toto pole je povinné!
									</p>
								)}
							</div>
							<p className="description">Kategorie:</p>
							<select
								className={'basicInput adminInput'}
								value={categoryChoice}
								onChange={evt => setCategoryChoice(evt.target.value)}
							>
								<option disabled hidden selected>Kategorie produktu</option>
								<option value="Online">Online</option>
								<option value="Osobně">Osobně</option>
							</select>
							<div className="my-2">
								{categoryErr && (
									<p className="error leading-7 p-0 m-0">
										Toto pole je povinné!
									</p>
								)}
							</div>
							<p className="description">Cena:</p>
							<NumberFormat
								className={'basicInput adminInput'}
								decimalScale={0}
								decimalSeparator={" "}
								allowNegative={false}
								placeholder={"Cena produktu"}
								thousandSeparator={"."}
								type={"text"}
								suffix={" Kč"}
								value={cena}
								onValueChange={(val)=>setCena(val.formattedValue)}
							/>
							<div className="my-2">
								{cenaErr && (
									<p className="error leading-7 p-0 m-0">
										Toto pole je povinné!
									</p>
								)}
							</div>
							<p className="description">Kusů skladem:</p>
							<input
								className={'basicInput adminInput'}
								type={'text'}
								placeholder={'Kusů skladem'}
								value={available}
								onChange={evt => setAvailable(evt.target.value)}
							/>
							<div className="my-2">
								{availableErr && (
									<p className="error leading-7 p-0 m-0">
										Toto pole je povinné!
									</p>
								)}
							</div>
							<p className="description">Obrázek:</p>
							<label className="z-1">
								<input
									type="file"
									className="hidden"
									// value={image}
									name="image"
									// @ts-ignore
									onChange={changeHandler}
									accept="image/png, image/gif, image/jpeg, image/jpg"
								/>
								<div className="flex input-container h-full cursor-pointer basicInput adminInput fileInput items-center gap-x-4 ">
									<img src={icon} alt="icon" className="w-10" />
									<input
										type="text"
										readOnly
										className="outline-none fileInput z-10"
										value={img ? img : startPhoto}
										// {...register('img', { required: true })}
									/>
								</div>
								<div className="my-2">
									{fileErr ? (
										<label className={'error leading-7 p-0 m-0'}>
											{fileErr}
										</label>
									) : null}
								</div>
							</label>
						</div>
						<p className="description">Krátký popis:</p>
						<div className={'rowForm'}>
							{
								<Editor
									apiKey="a8zubyyl16d8v8m0k5jnlgm34byg8qg0hoelznpecrq9io2l"
									// @ts-ignore
									onInit={(evt, editor) => (editorRef.current = editor)}
									initialValue={shortDescription}
									init={{
										height: 500,
										menubar: 'insert',
										images_upload_url: `${ADDRESS}/product-new-image`,
										automatic_uploads: true,
										images_upload_credentials: true,
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'image',
											'insertdatetime media table paste code help wordcount',
										],
										toolbar:
											'undo redo | formatselect | ' +
											'bold italic backcolor | alignleft aligncenter ' +
											'alignright alignjustify | bullist numlist outdent indent | ' +
											'image' +
											'removeformat | help',
										image_list: gallery,
										content_style:
											'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
									}}
								/>
							}
							<div className="my-2">
								{textErr ? (
									<label className={'error leading-7 p-0 m-0'}>{textErr}</label>
								) : null}
							</div>
						</div>
						<p className="description">Dlouhý popis:</p>
						<div className={'rowForm'}>
							{
								<Editor
									apiKey="a8zubyyl16d8v8m0k5jnlgm34byg8qg0hoelznpecrq9io2l"
									// @ts-ignore
									onInit={(evt, editor) => (editorRef2.current = editor)}
									initialValue={longDescription}
									init={{
										height: 800,
										menubar: 'insert',
										images_upload_url: `${ADDRESS}/product-new-image`,
										automatic_uploads: true,
										images_upload_credentials: true,
										plugins: [
											'advlist autolink lists link image charmap print preview anchor',
											'searchreplace visualblocks code fullscreen',
											'image',
											'insertdatetime media table paste code help wordcount',
										],
										toolbar:
											'undo redo | formatselect | ' +
											'bold italic backcolor | alignleft aligncenter ' +
											'alignright alignjustify | bullist numlist outdent indent | ' +
											'image' +
											'removeformat | help',
										image_list: gallery,
										content_style:
											'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
									}}
								/>
							}
							<div className="my-2">
								{textErr2 ? (
									<label className={'error leading-7 p-0 m-0'}>
										{textErr2}
									</label>
								) : null}
							</div>
						</div>
						{productErr !== '' ? (
							<p className="errorAlert">{productErr}</p>
						) : null}

						<div className="w-full text-center mt-5 mb-1">
							<button
								onClick={e => {
									log(e);
								}}
								type="submit"
								className="btn-default mx-auto rounded-xl text-white btn-add px-6 py-1.5 font-semibold"
							>
								Upravit
							</button>
						</div>
					</form>
				</div>
			</div>
		</motion.div>
	);
};

export default AdminProductsEdit;
