import { Dispatch, SetStateAction } from 'react';

interface IProps {
	activeSideBar: boolean;
	setActiveSideBar?: Dispatch<SetStateAction<boolean>>;
}

const SidebarButton = (props: IProps) => {
	const clickHandler = () => {
		if (props.setActiveSideBar !== undefined) {
			props.setActiveSideBar(!props.activeSideBar);
		}
	};
	return (
		<div
			onClick={() => {
				clickHandler();
			}}
			className="z-1"
		>
			<div className="sideBar-button mb-5 z-1">
				<span></span>
				<span></span>
				<span></span>
			</div>
		</div>
	);
};

export default SidebarButton;
