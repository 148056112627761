// react hooks
import React, { useState, useEffect } from 'react';

// interfaces, routes
import IPage from '../../interfaces/page';
import { ADDRESS } from '../../constants/routes';
import { motion } from 'framer-motion';
import { pageAnimation } from '../../components/animations';

//fetch
 import { FetchRows } from '../../components/fetch';

// components
import AdministrationTable from '../../components/adminTable';
import Sidebar from '../../components/adminSidebar';
import SidebarButton from '../../components/sideBarButton';

const AdminMessages: React.FunctionComponent<IPage> = () => {
	const colgroup = [5, 15, 15, 25, 20, 20];
	const thead = [
		'Id',
		'Datum',
		'Email',
		'Předmět zprávy',
		'Rozbalit',
		'Smazat',
	];
	const wantedParams = ['id', 'created_at', 'email', 'subject'];
	const buttons = [
		{ name: 'Rozbalit', type: 'unpack', table: '', key: '', url: '' },
		{
			name: 'Smazat',
			type: 'delete',
			table: 'messages',
			key: 'id',
			url: '',
		},
	];
	const additionalContent = [ 'message'];
	const additionalContentTXT = [ 'Zpráva: '];
	const [changeData, setChangeData] = useState(false);
	const [params, setParams] = useState({});
	const [btnText, setBtnText] = useState('Zobrazit zprávy obchodníků');

	const [activeSideBar, setActiveSideBar] = useState(Boolean);
	function HandleChange() {
		if (!changeData) {
			setParams([
				{
					column: 'email_to',
					value: null,
				},
			]);
			setBtnText('Zobrazit jen zprávy');
			setChangeData(true);
		} else if (changeData) {
			setParams([]);
			setBtnText('Zobrazit zprávy obchodníků');
			setChangeData(false);
		}
	}
	// const messages = [
	// 	{
	// 		id: '1',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '2',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '3',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '4',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '5',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '6',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '7',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '8',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '9',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '10',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// ];
	return (
		<motion.div
			className={'adminContainer'}
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
		>
			<div className={'addedControlls'}>
				<SidebarButton
					setActiveSideBar={setActiveSideBar}
					activeSideBar={activeSideBar}
				/>
				{/*<div>*/}
				{/*	<button*/}
				{/*		id={'adminHelperBtn'}*/}
				{/*		className={'adminHelperBtn'}*/}
				{/*		onClick={HandleChange}*/}
				{/*	>*/}
				{/*		{btnText}*/}
				{/*	</button>*/}
				{/*</div>*/}
			</div>
			<Sidebar activeSideBar={activeSideBar} />
			<div className={'tableWrapper'}>
				<AdministrationTable
					colgroup={colgroup}
					thead={thead}
					 tbody={FetchRows(`${ADDRESS}/fetch/messages`, changeData, params)}
					wantedParams={wantedParams}
					buttons={buttons}
					type={'addedRow'}
					additionalContent={additionalContent}
					additionalContentTXT={additionalContentTXT}


				/>
			</div>
		</motion.div>
	);
};

export default AdminMessages;
