// react hooks
import React, { useState, useEffect } from 'react';
//@ts-ignore
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import { motion } from 'framer-motion';
import { pageAnimation } from '../../components/animations';

// interfaces, routes
import IPage from '../../interfaces/page';
import { ADDRESS } from '../../constants/routes';

import sluzbyImg from '../../assets/images/sluzbyImg.png';
import sluzbyImg2 from '../../assets/images/sluzbyImg2.png';

// fetch
 import { FetchRows } from '../../components/fetch';

// components
import AdministrationTable from '../../components/adminTable';
import Sidebar from '../../components/adminSidebar';
import SidebarButton from '../../components/sideBarButton';

const AdminProducts: React.FunctionComponent<IPage> = props => {
	const colgroup = [10, 20, 30, 20, 20];
	const thead = [
		'Id',
		'Datum přidání',
		'Název',
		'Editovat',
		'Smazat',
	];
	const wantedParams = ['id', 'created_at', 'name'];
	const buttons = [
		{ name: 'Editovat', type: 'edit', table: '', key: '', url: '/admin/produkty/edit/' },
		{ name: 'Smazat', type: 'delete', table: 'products', key: 'id', url: '' },
	];
	const [activeSideBar, setActiveSideBar] = useState(Boolean);
    // const eshopSluzby = [
	// 		{
	// 			id: 1,
	// 			img: sluzbyImg,
	// 			label: 'ONLINE COACHING',
	// 			cena: '12.000 Kč',
	// 			shortDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			longDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			available: '200',
	// 			created_at: '27.12.2021',
	// 		},
	// 		{
	// 			id: 2,
	// 			img: sluzbyImg2,
	// 			label: 'PŘÍPRAVA NA ZÁVODY',
	// 			cena: '10.000 Kč',
	// 			shortDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			longDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			available: '200',
	// 			created_at: '27.12.2021',
	// 		},
	// 		{
	// 			id: 3,
	// 			img: sluzbyImg2,
	// 			label: 'ONLINE KONZULTACE',
	// 			cena: '2.000 Kč',
	// 			shortDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			longDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			available: '200',
	// 			created_at: '27.12.2021',
	// 		},
	// 		{
	// 			id: 4,
	// 			img: sluzbyImg,
	// 			label: 'ONLINE COACHING2',
	// 			cena: '12.000 Kč',
	// 			shortDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			longDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			available: '200',
	// 			created_at: '27.12.2021',
	// 		},
	// 		{
	// 			id: 5,
	// 			img: sluzbyImg,
	// 			label: 'PŘÍPRAVA NA ZÁVODY2',
	// 			cena: '10.000 Kč',
	// 			shortDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			longDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			available: '200',
	// 			created_at: '27.12.2021',
	// 		},
	// 		{
	// 			id: 6,
	// 			img: sluzbyImg,
	// 			label: 'ONLINE KONZULTACE2',
	// 			cena: '2.000 Kč',
	// 			shortDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			longDescription:
	// 				'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornare ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra lorem bibendum facilisis nunc. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac Lorem ipsum dolor sit amet, consectetur adipiscing elit. Venenatis donec tristique sit eget montes, ultricies id hac libero. Ornar ullamcorper quis quis arcu. Facilisis eget augue urna, pretium, blandit vitae. Laoreet orci ac pharetra. libero.',
	// 			available: '200',
	// 			created_at: '27.12.2021',
	// 		},
	// 	];
	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
			className={'adminContainer'}
		>
			<div className={'addedControlls'}>
				<SidebarButton
					setActiveSideBar={setActiveSideBar}
					activeSideBar={activeSideBar}
				/>
				<div>
					<Link
						to={`${ROUTES.ADMIN_PRODUKTY_ADD}`}
						id={'adminHelperBtn'}
						className={'adminHelperBtn'}
					>
						Přidat produkt
					</Link>
				</div>
			</div>
			<Sidebar activeSideBar={activeSideBar} />
			<div className={'tableWrapper'}>
				<AdministrationTable
					colgroup={colgroup}
					thead={thead}
					 tbody={FetchRows(`${ADDRESS}/fetch/products`)}
					//tbody={eshopSluzby}
					wantedParams={wantedParams}
					buttons={buttons}
					type={'addedRow'}
					additionalContent={null}
					additionalContentTXT={null}
					update={props.update}
				/>
			</div>
		</motion.div>
	);
};

export default AdminProducts;
