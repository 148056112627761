import { NavLink } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import Logo from "../assets/images/logoKaspar.png";
import React, { useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import $ from "jquery"

export default function Header(props) {

	const { pathname } = useLocation();
	const animateNavbar = {
		hidden: {
			y: '-100%',
			transition: {
				duration: 1,
				ease: 'easeInOut',
			},
		},
		show: {
			y: 0,
			transition: {
				duration: 0.9,
				ease: 'easeInOut',
			},
		},
	};

	const navbarLinks = [
		{ id: 2, name: 'O MNĚ', to: ROUTES.ABOUT },
		{ id: 3, name: 'SLUŽBY', to: ROUTES.ESHOP },
		// { id: 4, name: 'KOŠÍK', to: ROUTES.KOSIK },
	];

	const [isActive, setIsActive] = useState(false);

	const [isOpen, setIsOpen] = useState(false);

	const handleActive = () => {
		setIsActive(!isActive);
		setIsOpen(!isOpen);
	};

	const circleRef = useRef(null);

	const [jumboHeight, setJumboHeight] = useState(0);
	const [windowScroll, setWidnowScroll] = useState(0);
	useEffect(() => {
		if (pathname !== '/') {
			if (window.innerWidth < 768) {
				setJumboHeight(320 - 96);
			} else if (window.innerWidth < 1280) {
				setJumboHeight(440 - 96);
			} else {
				setJumboHeight(560 - 96);
			}
		} else {
			if (window.innerWidth >= 768 && window.innerWidth <= 880) {
				setJumboHeight(window.innerWidth - 96);
			} else if (window.innerHeight > 880) {
				setJumboHeight(880 - 96);
			} else {
				setJumboHeight(480 - 96);
			}
		}
		setWidnowScroll(window.scrollY);
	}, [pathname]);

	window.addEventListener('resize', () => {
		if (pathname !== '/') {
			if (window.innerWidth < 768) {
				setJumboHeight(320 - 96);
			} else if (window.innerWidth < 1280) {
				setJumboHeight(440 - 96);
			} else {
				setJumboHeight(560 - 96);
			}
		} else {
			if (window.innerWidth >= 768 && window.innerWidth <= 880) {
				setJumboHeight(window.innerWidth - 96);
			} else if (window.innerHeight > 880) {
				setJumboHeight(880 - 96);
			} else {
				setJumboHeight(480 - 96);
			}
		}
		setWidnowScroll(window.scrollY);
	});

	window.addEventListener('scroll', () => {
		setWidnowScroll(window.scrollY);
	});

	let arrayWithItems = props.cartItemsArray.filter(item => {
		return item.includes('cartItem');
	});

	// function triggerHtmlEvent(element, eventName) {
	// 	console.log("hello")
	// 	var event;
	// 	if(document.createEvent) {
	// 		event = document.createEvent('HTMLEvents');
	// 		event.initEvent(eventName, true, true);
	// 		element.dispatchEvent(event);
	// 	} else {
	// 		event = document.createEventObject();
	// 		event.eventType = eventName;
	// 		element.fireEvent('on' + event.eventType, event);
	// 	}
	// }

	// $('.language-1 a').click(function() {
	// 	var lang = $(this).data('lang');
	// 	var $frame = $('.goog-te-menu-frame:first');
	// 	$('.goog-te-menu-frame:first').contents().find('.goog-te-menu2-item span.text').each(function(){ if( $(this).html() == lang ) $(this).click();});
	// 	return false;
	// });
	$('.languages a').click(function() {
		var lang = $(this).data('lang');
		var $frame = $('.goog-te-menu-frame:first');
		$('.goog-te-menu-frame:first').contents().find('.goog-te-menu2-item span.text').each(function(){ if( $(this).html() == lang ) $(this).click();});
		return false;
	});
	return (
		<>
			<div
				ref={el => (el = circleRef)}
				className={
					windowScroll <= jumboHeight &&
					(pathname === '/' ||
						pathname === '/o-mne' ||
						pathname === '/eshop' ||
						pathname === '/kontakt' ||
						pathname === '/kosik' ||
						(pathname.includes('produkty')) && !pathname.includes('admin'))
						? 'h-24 navbar-header-container 2xl:px-80 xl:px-64 extra:px-40 md:px-32 sm:px-16  px-5 fixed w-screen flex items-center justify-between  z-50'
						: 'h-24 navbar-header-container 2xl:px-80 xl:px-64 extra:px-40 md:px-32 sm:px-16  px-5 fixed w-screen flex items-center justify-between  z-50 darkMenu'
				}
			>
				<div
					className="h-20 w-20 hidden absolute left-4 "
					id="navbar-starter"
				></div>
				<NavLink to={ROUTES.HOME} className="">
					<img src={Logo} alt="logo" className="lg:h-20 h-16" />
				</NavLink>
				<div
					className={isActive ? ' navbar-button isActive ' : 'navbar-button'}
					onClick={handleActive}
				>
					<span></span>
					<span></span>
					<span></span>
				</div>
				<div className="ul-header-list ">
					<ul className="flex pt-1  text-white">
						<div className={"flex flex-row languages language-1 mini-text relative "}>
						<li className={"header-li mini-text relative"} style={{height: "fit-content"}}>
							<a href="#" data-lang="čeština">CS</a>
						</li>|
							<li className={"header-li mini-text relative"} style={{height: "fit-content"}}>
							<a href="#" data-lang="angličtina">EN</a>
							</li>|
							<li className={"header-li mini-text relative"} style={{height: "fit-content"}}>
								<a href="#" data-lang="španělština">ES</a>
							</li>|
							<li className={"header-li mini-text relative"} style={{height: "fit-content"}}>
									<a href="#" data-lang="arabština">AR</a>
							</li>
						</div>
						<NavLink
							to={ROUTES.HOME}
							activeClassName="active-header"
							className="header-li mini-text relative"
							exact
						>
							<li className="uvod-li">ÚVOD</li>
						</NavLink>
						{navbarLinks.map(item => {
							const { name, id, to } = item;
							return (
								<NavLink
									to={to}
									activeClassName="active-header"
									className={
										id === 4 && arrayWithItems.length > 0
											? sessionStorage.getItem('step') &&
											  sessionStorage.getItem('step') !== 'thirdToFourth'
												? 'header-li mini-text relative kosik'
												: !sessionStorage.getItem('step')
												? 'header-li mini-text relative kosik'
												: 'header-li mini-text relative'
											: 'header-li mini-text relative'
									}
									key={id}
									data-content={
										arrayWithItems.length > 0 ? arrayWithItems.length : ''
									}
								>
									{name}
								</NavLink>
							);
						})}
						<NavLink to={ROUTES.KONTAKT} className="my-auto px-4">
							<li
								className={
									pathname === '/kontakt'
										? 'activeKontakt py-1 px-5 rounded-lg border-2 border-main-red  bg-transparent hover:bg-main-red  font-medium  text-lg text-white tracking-wider transitions'
										: 'py-1 px-5 rounded-lg border-2 border-main-red  bg-transparent hover:bg-main-red  font-medium  text-lg text-white tracking-wider transitions'
								}
							>
								KONTAKT
							</li>
						</NavLink>
						{props.logged &&
						<NavLink to={ROUTES.ADMIN_ZPRAVY} className="my-auto px-4">
							<li
								className={
									pathname.includes("/admin")
										? 'activeKontakt py-1 px-5 rounded-lg border-2 border-main-red  bg-transparent hover:bg-main-red  font-medium  text-lg text-white tracking-wider transitions'
										: 'py-1 px-5 rounded-lg border-2 border-main-red  bg-transparent hover:bg-main-red  font-medium  text-lg text-white tracking-wider transitions'
								}
							>
								ADMIN
							</li>
						</NavLink>
						}
					</ul>
				</div>
			</div>
			<motion.div
				className="menu-wrapper h-screen  left-0 top-0 w-screen fixed"
				variants={animateNavbar}
				animate={isActive ? 'show' : 'hidden'}
				initial="hidden"
				onClick={handleActive}
			>
				<ul className="flex flex-col text-center pt-24 items-center bg-gray-nadpis  shadow-xl  text-black ">
					<div className={"flex flex-row justify-center language-2 languages w-full mt-3 "}>
						<li className={"header-li w-full mt-3"} style={{height: "fit-content"}}>
							<a href="#" data-lang="čeština">CS</a>
						</li>|
						<li className={"header-li w-full mt-3"} style={{height: "fit-content"}}>
							<a href="#" data-lang="angličtina">EN</a>
						</li>|
						<li className={"header-li w-full mt-3"} style={{height: "fit-content"}}>
							<a href="#" data-lang="španělština">ES</a>
						</li>|
						<li className={"header-li w-full mt-3"} style={{height: "fit-content"}}>
							<a href="#" data-lang="arabština">AR</a>
						</li>
					</div>
					<NavLink
						to={ROUTES.HOME}
						activeClassName="active-header"
						className="header-li w-full mt-3"
						exact
						onClick={() => setIsActive(!isActive)}
					>
						<li className="uvod-li">ÚVOD</li>
					</NavLink>
					{navbarLinks.map(item => {
						const { name, id, to } = item;
						return (
							<NavLink
								to={to}
								activeClassName="active-header"
								className="header-li  w-full"
								key={id}
								onClick={() => setIsActive(!isActive)}
							>
								{name}
							</NavLink>
						);
					})}
					<NavLink
						to={ROUTES.KONTAKT}
						activeClassName="active-header"
						className="pb-7 pt-2 w-40"
					>
						<li
							className={
								pathname === '/kontakt'
									? 'activeKontakt py-1 px-5 rounded-lg border-2 border-main-red  bg-transparent hover:bg-main-red  font-medium  text-lg text-white tracking-wider transitions'
									: 'py-1 px-5 rounded-lg border-2 border-main-red  bg-transparent hover:bg-main-red  font-medium  text-lg text-white tracking-wider transitions'
							}
						>
							KONTAKT
						</li>
					</NavLink>
					{props.logged &&
					<NavLink
						to={ROUTES.ADMIN_ZPRAVY}
						activeClassName="active-header"
						className="pb-7 pt-2 w-40"
					>
						<li
							className={
								pathname.includes("/admin")
									? 'activeKontakt py-1 px-5 rounded-lg border-2 border-main-red  bg-transparent hover:bg-main-red  font-medium  text-lg text-white tracking-wider transitions'
									: 'py-1 px-5 rounded-lg border-2 border-main-red  bg-transparent hover:bg-main-red  font-medium  text-lg text-white tracking-wider transitions'
							}
						>
							ADMIN
						</li>
					</NavLink>
					}
				</ul>
			</motion.div>
		</>
	);
}
