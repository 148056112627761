import React from 'react';
import ITable from '../interfaces/table';
// import { deleteRow } from '../components/fetch';
// @ts-ignore
import $ from 'jquery';
// @ts-ignore
import { Link } from 'react-router-dom';
import { transform } from 'typescript';
import {deleteRow} from "./fetch";
import axios from "axios";
import {ADDRESS} from "../constants/routes";

const AdministrationTable: React.FunctionComponent<ITable> = (props: {
	buttons: any[] | null;
	type: string;
	thead: any[];
	tbody: any[];
	colgroup: any[];
	additionalContent: any[] | null;
	wantedParams: any[];
	additionalContentTXT: any[] | null;
	selectFrom?: any[];
	update?: () => void;
}) => {
	const deleteEvent = (id: string, key: string, table: string) => {
		deleteRow(id, key, table)
		if(props.update){
			props.update()
		}
		return
	};
	const actionEvent = (id: string) => {
		let order = props.tbody.filter(i =>  i.id == id)[0]
		let changeTo = ""
		switch (order.state){
			case "Čekající":
				changeTo = "Potvrzená"
				break
			case "Potvrzená":
				changeTo = "Dokončená"
				break;
		}
		axios.post(`${ADDRESS}/update-row/orders/${order.id}`, {change_to : changeTo}, {withCredentials: false})
			.then(res=> console.log(res.data))
		if(props.update){
			props.update()
		}
		return
	};
	const unpackEvent: any = (id: string, index: number) => {
		let x = document.querySelectorAll('.transitionRow');
		let y = document.querySelectorAll('.unpack');
		for (let i = 0; i < x.length; i++) {
			if (i !== index) {
				x[i].classList.add('hiddenRow');
				$(y[i]).text('Rozbalit');
			}
		}
		const row = document.getElementById(id);
		let btn = document.getElementById('unpack' + id);

		if (row === null || btn === null) {
			return;
		}

		if (row.classList.contains('hiddenRow')) {
			btn.innerText = 'Rozbaleno';
			return row.classList.remove('hiddenRow');
		} else {
			btn.innerText = 'Rozbalit';
			return row.classList.add('hiddenRow');
		}
	};
	const getNextStav = (id: string)=>{
		let order = props.tbody.filter(i =>  i.id == id)[0]
		switch (order.state){
			case "Čekající":
				return "Potvrdit"
			case "Potvrzená":
				return "Dokončit"
			case "Dokončená":
				return "Dokončená"
		}
	}

	const buttonEvent = (
		id: string,
		key: string,
		type: string,
		table: string,
		index: number,
		url: string,
	) => {
		if (type === 'delete') {
			deleteEvent(id, key, table);
		} else if (type === 'unpack') {
			unpackEvent(id, index);
		}else if (type === 'action'){
			actionEvent(id)
		}
	};
	const buttons = (id: string, indexX: number) => {
		if (props.buttons !== null) {
			return props.buttons.map((button, index) => {
				if (button.type !== 'edit') {
					return (
						<td key={index}>
							<button
								id={button.type + id}
								key={index}
								disabled={button.type == "action" && getNextStav(id) == "Dokončená" ? true : false}
								onClick={() =>
									buttonEvent(
										id,
										button.key,
										button.type,
										button.table,
										indexX,
										button.table,
									)
								}
								className={'btn ' + button.type}
							>
								{button.type == "action" ? getNextStav(id) : button.name}
							</button>
						</td>
					);
				}else {
					return (
						<td key={index}>
							<Link
								to={button.url + id}
								id={button.type + id}
								className={'btn ' + button.type}
							>
								{button.name}
							</Link>
						</td>
					);
				}
			});
		} else {
			return null;
		}
	};
const getProducts =  (content:string, idProduct: any)=>{
	let string =""
	let order = props.tbody[idProduct]
	if (content === "products"){
		let ids = order.products_id.split(",")
		for(let i = 0; i<ids.length; i++){
			if (props.selectFrom){
				let product = props.selectFrom.filter(e => e.id == ids[i])[0];
				string += product.name + ", "
			}
		}
	string = string.slice(0, -2)
	}else if (content=== "address"){
		string = order.street + " " + order.house_number + " " + order.city + " " + order.psc
	}else if(content === "health"){
		string = order.health == "Ne" ? order.health : (order.health + " - " + order.health_text)
	}
	return string
}
	const additionalRow = (
		id: any,
		content: any[] | null,
		contentTxT: any[] | null,
		indexOMEGA: any,
	) => {
		if (props.type === 'addedRow') {
			if (content && contentTxT)
				//console.log(props.tbody);
				return (
					<tr id={id} className={'transitionRow hiddenRow'}>
						<td colSpan={props.thead.length}>
							<div>
								{content.map((content, index) => {
									return (
										<div key={index}>
											<p>
												{contentTxT[index]}{' '}
												<span className={'additional'}>
													{content === "products" || content === "address" || content == "health"
														? getProducts(content, indexOMEGA):
														props.tbody[indexOMEGA][content]}
													{/*{props.tbody[indexOMEGA][content]}*/}
												</span>
											</p>
										</div>
									);
								})}
							</div>
						</td>
					</tr>
				);
		} else if (props.type !== 'addedRow') {
			return null;
		}
	};

	return (
		<table className={'adminTable'}>
			<colgroup>
				{props.colgroup.map((colgroup, index) => (
					<col key={index} style={{ width: colgroup + '%' }} />
				))}
			</colgroup>
			<thead>
				<tr>
					{props.thead.map((thead, index) => (
						<th key={index}>{thead}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{props.tbody.map((tbody, index) => {
					let rowName;
					if (props.additionalContent !== null) {
						rowName = 'additionalRow';
					} else {
						rowName = 'basicRow';
					}
					return (
						<React.Fragment key={index}>
							<tr id={'basicChild' + tbody.id} className={rowName}>
								{props.wantedParams.map((wanted, index) => {
									if (wanted != "whole_name") return <td key={index}>{tbody[wanted]}</td>;
									return <td key={index}>{tbody["name"] + " " + tbody["lastname"]}</td>
								})}
								{buttons(tbody.id, index)}
							</tr>
							{additionalRow(
								tbody.id,
								props.additionalContent,
								props.additionalContentTXT,
								index,
							)}
						</React.Fragment>
					);
				})}
			</tbody>
		</table>
	);
};

export default AdministrationTable;