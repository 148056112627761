export const pageAnimation = {
	hidden: {
		opacity: 0,
	},
	enter: {
		opacity: 1,
		transition: {
			duration: 0.5,
			when: 'beforeChildren',
			staggerChildren: 0.25,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.5,
		},
	},
};

export const landingImage = {
	hidden: {
		scale: 1.15,
		transition: {
			duration: 0.5,
		},
	},
	show: {
		scale: 1,
		transition: {
			duration: 0.5,
		},
	},
};