// react hooks
import React, { useState, useEffect } from 'react';

// interfaces, routes
import IPage from '../../interfaces/page';
import { ADDRESS } from '../../constants/routes';
import { motion } from 'framer-motion';
import { pageAnimation } from '../../components/animations';
// fetch
 import { FetchRows, getExtract } from '../../components/fetch';

// components
import AdministrationTable from '../../components/adminTable';
import Sidebar from '../../components/adminSidebar';
import SidebarButton from '../../components/sideBarButton';

const AdminEmails: React.FunctionComponent<IPage> = props => {
	const colgroup = [5, 15, 55, 25];
	const thead = ['Id', 'Datum', 'Email', 'Smazat'];
	const wantedParams = ['id', 'created_at', 'email'];
	const buttons = [
		{
			name: 'Smazat',
			type: 'delete',
			table: 'email_collection',
			key: 'id',
			url: '',
		},
	];
	const [exportText, setExportText] = useState('Stáhnout výpis');
	const [colorBtn, setColorBtn] = useState('');
	const [activeSideBar, setActiveSideBar] = useState(Boolean);
	// const emails = [
	// 	{
	// 		id: '1',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '2',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '3',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '4',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '5',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '6',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '7',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '8',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '9',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// 	{
	// 		id: '10',
	// 		created_at: '22.12.2021',
	// 		email: 'dogjdog@djgdo.com',
	// 		subject: 'subject',
	// 	},
	// ];
	return (
		<motion.div
			variants={pageAnimation}
			initial="hidden"
			animate="enter"
			exit="exit"
			transition={{ type: 'linear' }}
			className={'adminContainer'}
		>
			<div className={'addedControlls'}>
				<SidebarButton
					setActiveSideBar={setActiveSideBar}
					activeSideBar={activeSideBar}
				/>
				<div>
					<button
						id={'adminHelperBtn'}
						className={'adminHelperBtn ' + colorBtn}
						onClick={() =>
							getExtract(
								'email_collection',
								setExportText,
								'Výpis byl stáhnut',
								setColorBtn,
							)
						}
					>
						{exportText}
					</button>
				</div>
			</div>
			<Sidebar activeSideBar={activeSideBar} />
			<div className={'tableWrapper'}>
				<AdministrationTable
					colgroup={colgroup}
					thead={thead}
					 tbody={FetchRows(`${ADDRESS}/fetch/email_collection`)}
					//tbody={emails}
					wantedParams={wantedParams}
					buttons={buttons}
					type={'addedRow'}
					additionalContent={null}
					additionalContentTXT={null}
				/>
			</div>
		</motion.div>
	);
};

export default AdminEmails;
