import {useEffect, useState} from "react";
import axios from "axios";
import {ADDRESS} from "../constants/routes";


export const FetchRows: any[] | any = (url: string, changeData: any, params: object | null) => {
    const [tableData, setTableData] = useState([]);


    useEffect(() => {
        axios.get(url, {params, withCredentials: false},
        ).then((data) => {
            setTableData(data.data);
        })

    }, [params, url, changeData])

    return tableData;
}

export const deleteRow: (id: string, key: string, table: string) => void = (id, key, table) => {
    axios.post(`${ADDRESS}/delete-row/${table}/${key}/${id}`, {}, {withCredentials: false}).then((response) => {
        let basicChild = document.getElementById('basicChild' + id);

        if (basicChild) {
            basicChild.style.display = 'none';
        }
    })
}


export const getExtract: any = (table: string, state: any, btnSucText: string, color: any) => {

    axios({
        url: `${ADDRESS}/export/${table}`,
        method: 'GET',
        responseType: 'blob', // important
        withCredentials: false,
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Výpis emailů.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
        state(btnSucText);
        color("download");
    });
}

export const addRow: any = (table: string, data: any, suc: any, err: any) => {
    axios.post(`${ADDRESS}/save/${table}`, {data}, {withCredentials: false}).then((response) => {
        if(typeof response.data.error !== "undefined") {
            suc('');
            return err(response.data.error);
        }
        err('');
        return suc(response.data.success);
    });
}


export const freeEmail: any = (url: string, status: any, data: any, code: any) => {
    axios.post(url, data, {withCredentials: false}).then((response) => {
        status({'status': response.status, 'message': "Uživatel byl úspěšně zaregistrován."});
    }).catch(function (error) {
        console.log(error.response.data);
        status({'status': error.response.status, 'message': error.response.data.success});
    });
}

