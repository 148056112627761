export const ADDRESS = process.env.NODE_ENV === 'production' ? "https://api.tomaskaspar.cz" : "http://127.0.0.1:8000";

export const HOME = "/";
export const ESHOP = "/eshop";
export const ABOUT = "/o-mne";
export const PRODUKTY = "/produkty";
export const DETAIL = '/produkty/:id';
export const REGISTRACE = "/registrace";
export const PRIHLASENI = "/prihlaseni";
export const KOSIK = "/kosik";
export const ZAPOMENUTEHESLO = "/zapomenute-heslo";
export const COOKIES = "/cookies";
export const GDPR = "/gdpr";
export const KONTAKT = "/kontakt";
export const FORM= "/formular/:name"

export const ADMIN_ZPRAVY = "/admin/zpravy";
export const ADMIN_EMAILY = "/admin/emaily";
export const ADMIN_PRODUKTY = "/admin/produkty";
export const ADMIN_PRODUKTY_ADD = "/admin/produkty/novy";
export const ADMIN_PRODUKTY_EDIT = "/admin/produkty/edit/:id";
export const ADMIN_DOTAZNIKY = "/admin/dotazniky";
export const ADMIN_OBJEDNAVKY = "/admin/objednavky";

export const ODHLASENI = "/odhlaseni";
