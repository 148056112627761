// @ts-ignore
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import * as ROUTES from '../constants/routes';

interface ActiveSideBar {
	activeSideBar: boolean;
}

export default function Sidebar(props: ActiveSideBar) {
	const links = [
		{ id: 1, name: 'Zprávy', to: `${ROUTES.ADMIN_ZPRAVY}` },
		{ id: 2, name: 'Emaily', to: `${ROUTES.ADMIN_EMAILY}` },
		{ id: 3, name: 'Dotazníky', to: `${ROUTES.ADMIN_DOTAZNIKY}` },
		{ id: 4, name: 'Produkty', to: `${ROUTES.ADMIN_PRODUKTY}` },
		{ id: 5, name: 'Objednávky', to: `${ROUTES.ADMIN_OBJEDNAVKY}` },
		{ id: 6, name: 'Odhlášení', to: `${ROUTES.ODHLASENI}` },
	];

	const animateSidebar = {
		hidden: {
			height: '0rem',
			transition: {
				duration: 0.5,
				ease: 'easeInOut',
			},
		},
		show: {
			height: '100%',
			transition: {
				duration: 0.5,
				ease: 'easeInOut',
			},
		},
	};

	return (
		<motion.div
			variants={animateSidebar}
			animate={props.activeSideBar ? 'show' : 'hidden'}
			initial="hidden"
			className="sidebar"
		>
			<ul>
				{links.map((val: any, key: number) => {
					return (
						<li key={key}>
							<Link
								key={key}
								to={val.to}
								id={window.location.pathname.includes(val.to) ? 'active' : ''}
							>
								{val.name}
							</Link>
						</li>
					);
				})}
			</ul>
		</motion.div>
	);
}
