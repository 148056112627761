// react hooks
import React, { useState, useEffect } from 'react';
//@ts-ignore
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

import { motion } from 'framer-motion';
import { pageAnimation } from '../../components/animations';

// interfaces, routes
import IPage from '../../interfaces/page';
import { ADDRESS } from '../../constants/routes';

import sluzbyImg from '../../assets/images/sluzbyImg.png';
import sluzbyImg2 from '../../assets/images/sluzbyImg2.png';

// fetch
 import { FetchRows } from '../../components/fetch';

// components
import AdministrationTable from '../../components/adminTable';
import Sidebar from '../../components/adminSidebar';
import SidebarButton from '../../components/sideBarButton';
import axios from "axios";

const AdminOrders: React.FunctionComponent<IPage> = props => {
    const [update, forceUpdate] = useState(1)
    const [produkty, setProdukty] = useState([])
    const [objednavky, setObjednavky] = useState([])

    const nastavProdukty = async() => {
      await axios.get(`${ADDRESS}/fetch/products`, {withCredentials: false})
          .then(res => {
              setProdukty(res.data)
          })
        await axios.get(`${ADDRESS}/fetch/orders`, {withCredentials: false})
            .then(res => {
                setObjednavky(res.data)
            })
    }
    const updateProdukty = ( )=>{
        console.log("hello")
        nastavProdukty()
        forceUpdate(update+1)
    }
    useEffect(()=>{
        if(produkty.length < 1 || objednavky.length<1){
            nastavProdukty()
        }
    },[produkty, objednavky])
    useEffect(()=>{
        console.log("hello")
    }, [update])
    const colgroup = [5, 10, 20, 20, 15, 15, 15];
    const thead = [
        'Id',
        'Stav',
        'Datum přidání',
        'Jméno',
        "Akce",
       "Obsah",
        'Smazat',
    ];
    const wantedParams = ['id', 'state', 'created_at', 'whole_name'];
    const buttons = [
        { name: 'Akce', type: 'action', table: '', key: '', url: '' },
        { name: 'Rozbalit', type: 'unpack', table: '', key: '', url: '' },
        { name: 'Smazat', type: 'delete', table: 'orders', key: 'id', url: '' },
    ];
    const additionalContent = ['address',  'products','sum','payment', 'ico'];
    const additionalContentTXT = ['Adresa: ', 'Produkty: ','Cena: ','Způsob platby: ','IČO: '];
    const [activeSideBar, setActiveSideBar] = useState(Boolean);

     if (produkty.length < 1 || objednavky.length < 1) return <></>
    return (
        <motion.div
            variants={pageAnimation}
            initial="hidden"
            animate="enter"
            exit="exit"
            transition={{ type: 'linear' }}
            className={'adminContainer'}
        >
            <div className={'addedControlls'}>
                <SidebarButton
                    setActiveSideBar={setActiveSideBar}
                    activeSideBar={activeSideBar}
                />
                {/*<div>*/}
                {/*    <Link*/}
                {/*        to={`${ROUTES.ADMIN_PRODUKTY_ADD}`}*/}
                {/*        id={'adminHelperBtn'}*/}
                {/*        className={'adminHelperBtn'}*/}
                {/*    >*/}
                {/*        Přidat produkt*/}
                {/*    </Link>*/}
                {/*</div>*/}
            </div>
            <Sidebar activeSideBar={activeSideBar} />
            <div className={'tableWrapper'}>
                <AdministrationTable
                    colgroup={colgroup}
                    thead={thead}
                     // tbody={FetchRows(`${ADDRESS}/fetch/orders`)}
                    tbody={objednavky}
                    wantedParams={wantedParams}
                    buttons={buttons}
                    type={'addedRow'}
                    additionalContent={additionalContent}
                    additionalContentTXT={additionalContentTXT}
                    selectFrom={produkty}
                    update={updateProdukty}
                />
            </div>
        </motion.div>
    );
};

export default AdminOrders;