import Header from "./components/header";
import * as ROUTES from "./constants/routes";
import sluzbyImg from './assets/images/sluzbyImg.png';
import sluzbyImg2 from './assets/images/sluzbyImg2.png';
import { lazy, Suspense } from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import './App.scss';
import Footer from './components/footer';
import React,{ useEffect, useState } from 'react';
import ScrollToTop from './components/scrollToTop';

import AdminMessages from './pages/admin/admin-messages';
import AdminEmails from './pages/admin/admin-emails';
import AdminLogOut from "./pages/admin/admin-logOut";
import AdminQuestionnaire from "./pages/admin/admin-questionnaires";
import AdminProducts from "./pages/admin/admin-products";
import AdminProductsAdd from "./pages/admin/admin-products-add";
import AdminProductsEdit from "./pages/admin/admin-products-edit";
import AdminOrders from "./pages/admin/admin-orders";
import axios from "axios";
import {ADDRESS} from "./constants/routes";


const Home = lazy(() => import('./pages/home'));
const Eshop = lazy(() => import('./pages/eshop'));
const Detail = lazy(() => import('./pages/detail'));
const About = lazy(() => import('./pages/about'));
const ZapomenuteHeslo = lazy(() => import('./pages/zapomenute-heslo'));
const Prihlaseni = lazy(() => import('./pages/prihlaseni'));
const Kosik = lazy(() => import('./pages/kosik'));
const Cookies = lazy(() => import('./pages/cookies'));
const GDPR = lazy(() => import('./pages/gdpr'));
const Kontakt = lazy(() => import('./pages/kontakt'));
const NotFound = lazy(() => import('./pages/notFound'));
const Formular = lazy(() => import('./pages/formular'));

export default function App() {
	const [kosikError, setKosikError] = useState();
	const [totalPrice, setTotalPrice] = useState(0);
	const [cartItemsArray, setCartItemsArray] = useState([
		Object.keys(sessionStorage),
	]);
	const [done, setDone] = useState(false);
	const [step, setStep] = useState('');
	const [user, setUser] = useState(localStorage.getItem("user") ? true : false)
	const [eshopSluzby, setEshopSluzby] = useState([])
	const [update, setUpdate] = useState(1);
	const forceUpdate=()=>{
		setUpdate(update+1)
	}

	useEffect(() => {
		setCartItemsArray(Object.keys(sessionStorage));
	}, []);

	useEffect(() => {
		sessionStorage.removeItem('fourth');
	}, []);
	useEffect(()=>{}, [user])
	const getItems = async ()=>{
		await axios.get(`${ADDRESS}/fetch-public/products`, {withCredentials: false})
			.then(res=>{
				setEshopSluzby(res.data)
			})
			.catch(err=>console.log(err.response.error))
	}
	useEffect(()=>{
		getItems()
	},[update])


	let fullSessionStorage = element => element.includes('cartItem');

	return (
		<div className="flex flex-col justify-between min-h-screen">
			<Router>
				<Header cartItemsArray={cartItemsArray} done={done} logged={user} />
				<ScrollToTop />
				<Suspense fallback={<div className="h-screen bg-white"></div>}>
					<Switch>
						<Route path={"/not-found"} component={()=><NotFound/>} exact/>
						<Route path={ROUTES.FORM} component={()=> <Formular/>} exact/>
						<Route
							path={ROUTES.HOME}
							component={() => (
								<Home
									eshopSluzby={eshopSluzby}
									setKosikError={setKosikError}
									setCartItemsArray={setCartItemsArray}
									setStep={setStep}
								/>
							)}
							exact
						/>
						<Route
							path={ROUTES.ESHOP}
							component={() => (
								<Eshop
									setKosikError={setKosikError}
									eshopSluzby={eshopSluzby}
									setCartItemsArray={setCartItemsArray}
									setStep={setStep}
								/>
							)}
						/>
						<Route
							path={ROUTES.KOSIK}
							exact
							component={() => (
								<Kosik
									kosikError={kosikError}
									setKosikError={setKosikError}
									cartItemsArray={cartItemsArray}
									setCartItemsArray={setCartItemsArray}
									totalPrice={totalPrice}
									setTotalPrice={setTotalPrice}
									eshopSluzby={eshopSluzby}
									fullSessionStorage={fullSessionStorage}
									done={done}
									setDone={setDone}
									step={step}
									setStep={setStep}
									update={forceUpdate}
								/>
							)}
						/>
						<Route path={ROUTES.ABOUT} component={() => <About />} />
						<Route
							path={ROUTES.DETAIL}
							component={() => (
								<Detail
									eshopSluzby={eshopSluzby}
									setKosikError={setKosikError}
									setCartItemsArray={setCartItemsArray}
									setStep={setStep}
								/>
							)}
						/>
						<Route
							path={ROUTES.ZAPOMENUTEHESLO}
							component={() => <ZapomenuteHeslo />}
						/>
						<Route path={ROUTES.PRIHLASENI} component={() => <Prihlaseni logged={setUser}/>} />
						<Route path={ROUTES.COOKIES} component={() => <Cookies />} />
						<Route path={ROUTES.GDPR} component={() => <GDPR />} />
						<Route path={ROUTES.KONTAKT} component={() => <Kontakt />} />
						<Route path={ROUTES.ODHLASENI} component={()=><AdminLogOut logged={setUser}/>} exact />

						{/* admin */}
						{user ?
							<Route  path={ROUTES.ADMIN_ZPRAVY} component={AdminMessages} exact />
							: <Redirect to={"/not-found"}/>
						}
						{user ?
							<Route path={ROUTES.ADMIN_EMAILY} component={AdminEmails} exact />
							: <Redirect to={"/not-found"}/>
						}
						{user ?
							<Route
								path={ROUTES.ADMIN_DOTAZNIKY}
								component={AdminQuestionnaire}
								exact
							/>
							: <Redirect to={"/not-found"}/>
						}
						{user ?
							<Route
								path={ROUTES.ADMIN_PRODUKTY}
								component={() => <AdminProducts update={forceUpdate}/>}
								exact
							/>
							: <Redirect to={"/not-found"}/>
						}
						{user ?
							<Route
								path={ROUTES.ADMIN_OBJEDNAVKY}
								component={AdminOrders}
								exact
							/>
							: <Redirect to={"/not-found"}/>
						}
						{user ?
							<Route
								path={ROUTES.ADMIN_PRODUKTY_ADD}
								component={()=><AdminProductsAdd update={forceUpdate}/>}
								exact
							/>
							: <Redirect to={"/not-found"}/>
						}
						{user ?
							<Route
								path={ROUTES.ADMIN_PRODUKTY_EDIT}
								component={() => <AdminProductsEdit update={forceUpdate} />}
								exact
							/>
							: <Redirect to={"/not-found"}/>
						}


						<Redirect to={"/not-found"} />

					</Switch>
				</Suspense>
				<Footer logged={user} />
			</Router>
		</div>
	);
}
